import { useState, useCallback, useEffect } from 'react'
import type { NextPage } from 'next'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useTranslation } from 'react-i18next'

const LoginTemplate = dynamic(
  () => import('../components/templates/LoginTemplate'),
  { ssr: false },
)

export type LoginPage =
  | 'selectLanguage'
  | 'top'
  | 'login'
  | 'register'
  | 'resetPassword'

const Login: NextPage = () => {
  const [page, setPage] = useState<LoginPage>('selectLanguage')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const initialState =
        window.localStorage.getItem('i18nextLng') === 'ja' ||
        window.localStorage.getItem('i18nextLng') === 'en'
          ? 'top'
          : 'selectLanguage'
      setPage(initialState)
    }
  }, [])

  const onClickPage = useCallback(
    (page: LoginPage) => () => {
      setPage(page)
    },
    [setPage],
  )

  const { ready } = useTranslation('main', { useSuspense: false })

  return (
    <div>
      <Head>
        <title>ログイン | YOMOGI+</title>
      </Head>
      {ready && <LoginTemplate page={page} onClickPage={onClickPage} />}
    </div>
  )
}

export default Login
